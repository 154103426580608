// Built-in import
import * as React from "react";

// Internal import
import {
  AuthSubmitButton,
  TitleWithDescription,
  TitleWithValue,
} from "@/components/shared";
// External import
import { Stack, alpha } from "@mui/material";

import Divider from "@mui/material/Divider";
import { UpdateInfoModalWrapper } from "../../partials";
import routes from "@/navigation/routes";
import { useAppSelector } from "@/store/store";
import { useGlobalContext } from "@/context/global-context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userSelector } from "@/store/slices/auth/selectors";

interface ISuccessRegistration {}

const SuccessRegistration: React.FC<ISuccessRegistration> = () => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  // navigator
  const navigator = useNavigate();
  // data user
  // const {} =

  //getting user information
  const userInfo = useAppSelector(userSelector);

  const { closeModal } = useGlobalContext();
  const handleFinlizeProfile = () => {
    navigator(`/${routes.dashboard.index}`);
    closeModal();
    window.location.reload();
  };

  return (
    <UpdateInfoModalWrapper>
      <Stack spacing={2}>
        <TitleWithDescription
          title={t("common:your_registration_was_successful")}
          description={t("common:welcome_to_sepehrino_investors")}
          primaryColor="text.primary"
          headerVariant="headingBlack"
          descriptionVariant="bodyMedium"
          spacing={1}
        />
        <Divider sx={{ flexGrow: 1 }} />
        <Stack spacing={2}>
          <TitleWithValue
            title={t("common:mobile_number")}
            value={userInfo?.mobile || "-"}
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            colorValue="black"
            variantValue="smallBold"
            variantTitle="smallMedium"
          />
          <TitleWithValue
            title={t("common:name")}
            value={userInfo?.name || "-"}
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            colorValue="black"
            variantValue="smallBold"
            variantTitle="smallMedium"
          />
          <TitleWithValue
            title={t("common:last_name")}
            value={userInfo?.last_name || "-"}
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            colorValue="black"
            variantValue="smallBold"
            variantTitle="smallMedium"
          />
          <TitleWithValue
            title={t("common:national_code")}
            value={userInfo?.national_code || "-"}
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            colorValue="black"
            variantValue="smallBold"
            variantTitle="smallMedium"
          />
          <TitleWithValue
            title={t("common:sajam_code")}
            value={userInfo?.sajam_code || "-"}
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            colorValue="black"
            variantValue="smallBold"
            variantTitle="smallMedium"
          />
          <TitleWithValue
            title={t("common:sheba_number")}
            value={userInfo?.sheba_code || "-"}
            direction={"column"}
            alignItems="flex-start"
            colorTitle="text.secondary"
            colorValue="black"
            variantValue="smallBold"
            variantTitle="smallMedium"
          />
        </Stack>
        <AuthSubmitButton
          title={t("login_to_system")}
          btnType={"button"}
          handleClick={handleFinlizeProfile}
        />
      </Stack>
    </UpdateInfoModalWrapper>
  );
};

export default SuccessRegistration;
