import GoogleRecaptcha from "react-google-recaptcha"

interface Props {
  handleChange?: (val: string) => void
}

const Recaptcha = ({ handleChange }: Props) => {
  return (
    <GoogleRecaptcha
      sitekey={"6Ld5imEpAAAAACU_QuK6pgWB2mVLY4WBzZL-I3Kk"}
      onChange={(success) => handleChange(success)}
    />
  )
}

export default Recaptcha
