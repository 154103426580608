// External import
import { Button, Stack } from "@mui/material"
// Built-in import
import { FC, useState } from "react"
import {
  actionSetCurrentStep,
  actionSetSelectedType
} from "@/store/slices/auth"

import EnterNationalCode from "./enter-national-code"
import EnterSejamOtp from "./enter-sejam-otp"
import { IUser } from "@/types/user"
import { SajamSystemInformationTitle } from "./subtitle"
import UpdateInfoModalWrapper from "../content-wrapper"
// Internal import
import { UserAuthGuidance } from "@/components/shared"
import UserOverView from "./user-overview"
import { useDispatch } from "react-redux"
import { useGlobalContext } from "@/context/global-context"
import { useTranslation } from "react-i18next"

interface ISajamSystemInformation {}

const SajamSystemInformation: FC<ISajamSystemInformation> = (props) => {
  const [natioanlCode, setNationalCode] = useState("")

  // Getting translation object
  const { t } = useTranslation(["common"])
  //getting redux dispatcher
  const dispatcher = useDispatch()

  const handleChangeStep = () => {
    dispatcher(actionSetCurrentStep(null))
    dispatcher(actionSetSelectedType(null))
  }

  const { showModal, closeAllModal } = useGlobalContext()
  const onGetInformation = (user: IUser) =>
    showModal(
      <UserOverView
        title={t("common:fetched_information_succesfully_from_sejam")}
        user={user}
        end={
          <Button
            variant="contained"
            onClick={() => (window.location.href = "/dashboard/profile")}
          >
            {t("common:return_to_profile")}
          </Button>
        }
      />
    )

  let view = <EnterNationalCode onSubmit={setNationalCode} />

  if (natioanlCode)
    view = (
      <EnterSejamOtp
        national_code={natioanlCode}
        onInformation={onGetInformation}
      />
    )

  return (
    <UpdateInfoModalWrapper handleBack={handleChangeStep}>
      <Stack spacing={3}>
        {/* title */}
        <SajamSystemInformationTitle />
        {/* form */}
        {view}
        <UserAuthGuidance
          question={`${t("dont_have_sejam_account_yet")}؟`}
          description={t("click_here")}
          handleClick={() => {
            window.open("https://sejam.ir")
          }}
        />
      </Stack>
    </UpdateInfoModalWrapper>
  )
}

export default SajamSystemInformation
