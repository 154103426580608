// Built-in import
import * as React from "react"
// External Import
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
// Internal Import
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper"
import { TitleWithValue } from "@/components/shared"
import ReceiverDetails from "./receiver-details"
import PaymentDetails from "./payment-details"
import ActionRenderer from "./action-renderer"

interface IPaymentDetailsContentProps {
  item: any
}

const PaymentDetailsContent: React.FunctionComponent<IPaymentDetailsContentProps> =
  ({ item }) => {
    // Getting Translation object
    const { t } = useTranslation(["common"])

    return (
      <ModalContentWrapper title={t("common:payment_details")}>
        <Stack spacing={2}>
          <TitleWithValue
            alignItems="flex-start"
            direction="column"
            title={t("common:receiver")}
            value={<ReceiverDetails item={item.receiver} />}
            colorTitle="text.primary"
          />
          <TitleWithValue
            alignItems="flex-start"
            direction="column"
            title={t("common:payment_details")}
            value={<PaymentDetails item={item.paymentDetails} />}
            colorTitle="text.primary"
          />
        </Stack>
        <Stack alignItems="flex-end">
          <ActionRenderer item={item.paymentDetails} />
        </Stack>
      </ModalContentWrapper>
    )
  }

export default PaymentDetailsContent
