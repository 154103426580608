import { IMedia } from "@/types/media"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

//Initial state type
export interface IProperty {
  title: string
  description?: string
  background?: string
  average_profit?: string | null
  farabourse_reference?: string | null
  owner_name?: string
  owner_id?: number | null
  excerpt?: string
  monthly_wage?: number
  period_months?: number | null
  is_guaranteed?: string
  has_invested?: boolean
  collateral_type?: string
  min_investment_quantity?: number
  max_investment_quantity?: number
  investment_ratio?: number
  risk_statement?: string
  media?: IMedia[]
  finance_statement?: string
  company_statement?: string
  required_investment_quantity?: string | null
  market_statement?: string
  total_investment_quantity?: string
  total_investment_percent?: number
  advantage_statement?: string
  status?: string
  total_shares?: number
  meta?: any[]
  id?: string
}
export interface IServerProperty extends IProperty {
  id: string
  created_at: string
  updated_at: string
  expires_at: string
  sold_shares: number
  media: IMedia[]
  status: string //should be change in the future
}

//Initial state
const initialState: IProperty = {
  title: ""
}

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    changeProperty(state, action: PayloadAction<any>) {
      if (action.payload && Object.keys(action.payload).length) {
        for (let key of Object.keys(action.payload)) {
          state[key] = action?.payload?.[key]
        }
      }
    },
    resetProperty: () => initialState
  }
})
export const { changeProperty, resetProperty: actionResetProperty } =
  propertySlice.actions

export default propertySlice.reducer
