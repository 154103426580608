// Built-in import
import * as React from "react";
import * as Yup from "yup";

// External import
import { Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import {
  IProperty,
  changeProperty,
  IServerProperty,
} from "@/store/slices/property";

import { AddPropertyStepTypes } from "@/types/properties";
// Internal import
import { AddPropertyStepsActions } from "../../actions";
import GalleryUploader from "@/components/shared/gallery-uploader";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUploader } from "@/hook";
import { Project } from "@/services";
import { toaster } from "@/components/shared/toaster";

interface IBasicPlanInformation {
  property?: IServerProperty;
  isEdit?: boolean;
}

const BasicPlanInformation: React.FC<IBasicPlanInformation> = ({
  property,
  isEdit = false,
}) => {
  //Getting dispatcher
  const dispatcher = useDispatch();

  // Getting translation object
  const { t } = useTranslation(["common", "validation"]);
  // Getting navigate
  const navigator = useNavigate();
  //setting primary media index
  const [primaryIndex, setPrimaryIndex] = React.useState(null);
  //setting save project changes loading

  const [saveLoading, setSaveLoading] = React.useState(false);

  // Formik
  const {
    values,
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: property?.title || "",
      owner_name: property?.owner_name || "",
      description: property?.description || "",
      excerpt: property?.excerpt || "",
      media: property?.media || [],
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t("validation:this_field_is_required")),
      owner_name: Yup.string().required(t("validation:this_field_is_required")),
      description: Yup.string().required(
        t("validation:this_field_is_required")
      ),
      excerpt: Yup.string().required(t("validation:this_field_is_required")),
    }),
    onSubmit: (values) => {
      navigator(`?type=${AddPropertyStepTypes.CapitalizationDetails}`);
      //Dispatch to the store
      dispatcher(changeProperty(values));
    },
  });

  React.useEffect(() => {
    if (property?.media?.length)
      for (let medIndex in property.media) {
        if (property?.media[medIndex].collection_name === "background") {
          setFieldValue("background", property?.media[medIndex].id);
          setPrimaryIndex(medIndex);
        } else {
          setFieldValue("background", property?.media[0].id);
          setPrimaryIndex(0);
        }
      }
  }, [property?.media]);

  //save project in current step
  const handleSaveProject = async () => {
    setSaveLoading(true);
    try {
      await Project.Update(property.id, values);
      toaster.success(t("your_project_updated_successfully"));
      dispatcher(changeProperty(values));
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit} width={"100%"}>
      <Paper
        sx={{
          my: 1,
          p: [2, , 4],
          py: [4, , 4],
          border: "1px solid",
          borderRadius: "12px",
          borderColor: (t) => t.palette.grey[300],
        }}
      >
        <Stack spacing={8}>
          <Stack spacing={3}>
            <Typography variant="bodyBold" color="text.primary">
              {t("common:enter_basic_information_of_your_property")}
            </Typography>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={t("common:property_name")}
                    {...getFieldProps("title")}
                    placeholder={t("common:property_name")}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={t("common:property_owner")}
                    {...getFieldProps("owner_name")}
                    placeholder={t("common:property_owner")}
                    error={Boolean(touched.owner_name && errors.owner_name)}
                    helperText={touched.owner_name && errors.owner_name}
                  />
                </Grid>
                <Grid item xs={12} order={{ xs: -1, md: 0 }}>
                  <TextField
                    fullWidth
                    label={t("common:brief_explanation_plan")}
                    {...getFieldProps("excerpt")}
                    error={Boolean(touched.excerpt && errors.excerpt)}
                    helperText={touched.excerpt && (errors.excerpt as string)}
                  />
                </Grid>
                <Grid item xs={12} order={{ xs: -1, md: 0 }}>
                  <TextField
                    fullWidth
                    label={t("common:complete_description_of_property")}
                    multiline
                    rows={6}
                    {...getFieldProps("description")}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GalleryUploader
                    defaultItems={values?.media || []}
                    onChange={(items) => setFieldValue("media", items)}
                    onItemClick={(item: any, index: number) => {
                      setPrimaryIndex(index);
                      setFieldValue("background", item);
                    }}
                    primaryIndex={primaryIndex}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <AddPropertyStepsActions
            isEdit={Boolean(isEdit)}
            saveLoading={saveLoading}
            handleSaveProperty={handleSaveProject}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default BasicPlanInformation;
