import * as React from "react";
import { Stack } from "@mui/material";
import { RegisterAsInvestorTitle } from "./subtitle";
import { AuthSubmitButton } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { UserTypesCards } from "./user-cards";
import { CompleteInfoType } from "@/types/auth";
import UpdateInfoModalWrapper from "../content-wrapper";

interface IUserTypeSelectionProps {
  handleChangeStep: (user: CompleteInfoType) => void;
}

const UserTypeSelection: React.FunctionComponent<IUserTypeSelectionProps> = ({
  handleChangeStep,
}) => {
  //get translation object
  const { t } = useTranslation();
  //get react router navigator
  const [userType, setUserType] = React.useState<"Real" | "Legal" | null>(null);

  const handleChangeRegisterType = (value: "Real" | "Legal") => {
    setUserType(value);
  };

  return (
    <UpdateInfoModalWrapper>
      <Stack spacing={5} p={2} alignItems={{ xs: "center", md: "start" }}>
        <RegisterAsInvestorTitle />
        <UserTypesCards
          handleChange={(val) => handleChangeRegisterType(val)}
          registerType={userType}
        />
        <AuthSubmitButton
          title={t("next_step")}
          disabled={!userType}
          btnType={"button"}
          handleClick={() => handleChangeStep(userType as CompleteInfoType)}
        />
      </Stack>
    </UpdateInfoModalWrapper>
  );
};
export default UserTypeSelection;
