import { Button, Stack, Typography } from "@mui/material"
import {
  LoadingEmbraced,
  OTPPureHandler,
  UserAuthGuidance
} from "@/components/shared"
import { actionSetToken, actionSetUser } from "@/store/slices/auth"
import { useEffect, useState } from "react"

import { Auth } from "@/services"
import { IUser } from "@/types/user"
import toaster from "@/components/shared/toaster/toaster"
import { useDispatch } from "react-redux"
import { useGlobalContext } from "@/context/global-context"
import { useTimer } from "@/hook"
import { useTranslation } from "react-i18next"

type Props = {
  national_code: string
  onInformation?: (user: IUser) => void
}

export default function EnterSejamOtp({ national_code, onInformation }: Props) {
  const { showModal } = useGlobalContext()

  const { t } = useTranslation(["common"])

  const { time, startTimer, isDisabled } = useTimer(60000)
  //start timer when we open the login field for fist time
  useEffect(() => {
    startTimer()
  }, [])

  //define resend code title when counter start or stop
  const resendCodeTitle = isDisabled
    ? time.seconds && time.minutes
      ? t("common:until_get_again_with_sec", time)
      : !time.minutes
      ? t("common:until_get_again_sec", { seconds: time.seconds })
      : t("common:until_get_again", { minutes: time.minutes })
    : t("common:resend_code")

  const dispatcher = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const stepTwoHandler = async (code: string) => {
    setIsSubmitting(true)
    try {
      const res = await Auth.sejamStepTwo(national_code, code)
      const accessToken = res.data.data["access-token"]
      const accessUser = res.data.data.user
      dispatcher(actionSetUser({ user: accessUser }))
      dispatcher(actionSetToken({ token: accessToken }))
      if (onInformation) onInformation(accessUser)
    } catch (e) {
    } finally {
      setIsSubmitting(false)
    }
  }

  const [isGettingCode, setIsGettingCode] = useState(false)
  const sendOtpCodeAgain = async () => {
    setIsGettingCode(true)
    try {
      await Auth.sejamStepOne(national_code)
      toaster.success(t("common:otp_has_been_sent_again"))
      startTimer()
    } catch (e) {
      toaster.error(e)
    } finally {
      setIsGettingCode(false)
    }
  }

  let view = (
    <Stack spacing={2} sx={{ width: 300, maxWidth: "100%", margin: "0 auto" }}>
      <Typography variant="bodyLight">
        {t("common:please_insert_otp_code")}
      </Typography>
      <OTPPureHandler length={5} onCompleted={stepTwoHandler} />
      <UserAuthGuidance
        isDisabled={isDisabled || isGettingCode}
        handleClick={sendOtpCodeAgain}
        question={t("common:didnt_receive_confirm_code")}
        description={resendCodeTitle}
      />
    </Stack>
  )

  if (isSubmitting) view = <LoadingEmbraced />

  if (!national_code) return null

  return <Stack sx={{ minHeight: 200 }}>{view}</Stack>
}
