import { IUser } from "@/types/user"
import { ReactNode } from "react"
import { Stack } from "@mui/material"
import { TitleWithValue } from "@/components/shared"
import { useTranslation } from "react-i18next"

type Props = {
  user: IUser
  title?: string
  subTitle?: string
  end?: ReactNode
}

export default function UserOverView({ user, title, subTitle, end }: Props) {
  const { t } = useTranslation(["common"])

  if (!user) return null

  return (
    <Stack p={4} spacing={2} alignItems={"flex-start"}>
      <TitleWithValue
        direction={"column"}
        title={title}
        value={subTitle}
        hasDoubleDot={false}
        variantTitle="headingBlack"
        variantValue="bodyLight"
        alignItems={"flex-start"}
      />
      <Stack
        spacing={2}
        sx={{
          borderTop: 1,
          borderColor: (t) => t.palette.divider,
          width: "100%",
          pt: 2,
          mt: 2
        }}
      >
        {[
          {
            title: t("common:mobile_number"),
            value: user.mobile || ""
          },
          {
            title: t("common:name"),
            value: user.first_name || ""
          },
          {
            title: t("common:last_name"),
            value: user.last_name || ""
          },
          {
            title: t("common:national_code"),
            value: user.national_code || ""
          },
          {
            title: t("common:sejam_code"),
            value: user.sajam_code || ""
          },
          {
            title: t("common:sheba_number"),
            value: user.sheba_code || ""
          }
        ].map(({ title, value }, index) => (
          <TitleWithValue
            alignItems={"flex-start"}
            direction={"column"}
            key={index}
            title={title}
            value={value}
          />
        ))}
      </Stack>
      {!!end && end}
    </Stack>
  )
}
