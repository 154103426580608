import * as React from "react"

import { IconButton, Stack, Typography } from "@mui/material"

import { AllVariants } from "@/mui/theme/declarations"
import CloseIcon from "@mui/icons-material/Close"
import { useGlobalContext } from "@/context/global-context"

interface IModalContentWrapperProps {
  title?: string
  children: React.ReactNode
  titleVariant?: AllVariants
  onFetch?: () => void
}

const ModalContentWrapper: React.FunctionComponent<
  IModalContentWrapperProps
> = ({ title, children, titleVariant = "bodyBold", onFetch }) => {
  // Getting global context
  const { closeModal } = useGlobalContext()

  return (
    <Stack spacing={4} className="modal-content-wrapper">
      {Boolean(title) && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            bgcolor: (t) => t.palette.common.white,
            borderBottom: 1,
            borderColor: (t) => t.palette.divider
          }}
          className="title-holder"
        >
          <Typography
            variant={titleVariant}
            color="text.primary"
            sx={{
              py: 2
            }}
          >
            {title}
          </Typography>
          {/* Closing modal */}
          <IconButton
            onClick={() => {
              closeModal()
              if (onFetch) onFetch()
            }}
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 0
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* Closing modal */}
        </Stack>
      )}
      {children}
    </Stack>
  )
}

export default ModalContentWrapper
