import * as React from "react";
import { Stack, Button, Divider } from "@mui/material";
import { ITransaction } from "@/types/transaction";
import { useTranslation } from "react-i18next";
import FinancialDetailItem from "./detail-item";
import PaymentDetailsRenderer from "../payment-details/payment-details-renderer";

interface IFinancialDetailsProps {
  item: any;
}

const FinancialDetails: React.FunctionComponent<IFinancialDetailsProps> = ({
  item,
}) => {
  //getting translation object
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <FinancialDetailItem
        title={t("transaction_title")}
        value={item.titleOfProperty}
      />
      <Divider />
      <FinancialDetailItem title={t("payer")} value={item.payer} />
      <Divider />
      <FinancialDetailItem title={t("receiver")} value={item.receiver} />
      <Divider />
      <FinancialDetailItem
        title={t("amount_of_transaction")}
        value={item.amountOfTransaction}
      />
      <Divider />
      <FinancialDetailItem title={t("date")} value={item.date} />
      <Divider />
      <FinancialDetailItem
        title={t("tracking_code")}
        value={item.trackingCode}
      />
      <Divider />
      <Stack direction={"row"} justifyContent="end">
        <PaymentDetailsRenderer item={item} />
      </Stack>
    </Stack>
  );
};

export default FinancialDetails;
