import { api, getEndpoint } from "@/api"

class Auth {
  public static getOtp(
    mobile: string,
    isResetPassword?: boolean,
    is_register?: boolean
  ) {
    return api.post(getEndpoint("authStepOne"), {
      ...((is_register && { is_register: 1 }) || {}),
      mobile,
      ...(isResetPassword && { is_reset_password: isResetPassword }),
    })
  }
  public static register(
    mobile: string,
    password: string,
    password_confirmation: string,
    terms: boolean | string | number,
    role: string,
    type: string
  ) {
    return api.post(getEndpoint("register"), {
      mobile,
      password,
      password_confirmation,
      terms,
      role,
      old_password: password,
      type,
    })
  }
  public static updateUser(
    id: string | number,
    password: string,
    terms: boolean | string | number,
    role: string,
    type: string
  ) {
    return api.put(getEndpoint("singleUser", { id }), {
      password,
      password_confirmation: password,
      terms,
      role,
      type,
    })
  }
  public static otpStepTwo(
    mobile: string,
    otp: string,
    role: string,
    password?: string
  ) {
    return api.post(getEndpoint("authStepTwo"), {
      mobile,
      otp,
      role,
      ...(password && { password }),
    })
  }
  public static credential(mobile: string, password: string) {
    return api.post(getEndpoint("authViaCredential"), {
      mobile,
      password,
    })
  }
  public static resendOtp(mobile: string) {
    return api.post(getEndpoint("authResendOtp"), {
      mobile,
    })
  }
  public static resetPassword(token: string, password: string) {
    return api.put(
      getEndpoint("resetPassword"),
      {
        password,
        password_confirmation: password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }
  public static sejamStepOne(national_code: string, recaptcha?: string) {
    return api.post(getEndpoint("authSejamStepOne"), {
      national_code,
      ...(recaptcha && { recaptcha }),
    })
  }
  public static sejamStepTwo(national_code: string, otp: string) {
    return api.post(getEndpoint("authSejamStepTwo"), { national_code, otp })
  }
}

export default Auth
