// Built-in import
import * as React from "react";
import * as Yup from "yup";

// Internal import
import {
  AuthSubmitButton,
  ShebaTextField,
  TitleWithValue,
} from "@/components/shared";
// External import
import { Grid, Stack, TextField } from "@mui/material";

import {
  actionSetCurrentStep,
  actionUpdateUserInfo,
} from "@/store/slices/auth";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IUser } from "@/types/user";
import { CompleteInfoType } from "@/types/auth";
import { useAuth } from "@/hook";

interface ICompleteInformationForm {
  user?: IUser;
}

const CompleteInformationForm: React.FC<ICompleteInformationForm> = ({
  user,
}) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);

  const { isRole } = useAuth();
  const isInvestor = isRole("investor");
  //getting redux dispatcher
  const dispatcher = useDispatch();
  // Formik
  const { handleSubmit, getFieldProps, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        company_name: user?.company_name || "",
        sajam_code: user?.sajam_code || "",
        international_code: user?.international_code || "",
        company_bank_number: user?.company_bank_number || "",
        company_economical_code: user?.company_economical_code || "",
        company_sheba_code: user?.company_sheba_code || "",
        economical_code: user?.economical_code || "",
        company_address: user?.company_address || "",
      },
      validationSchema: Yup.object().shape({
        company_name: Yup.string().required(
          t("validation:this_field_is_required")
        ),
        company_bank_number: Yup.string().required(
          t("validation:this_field_is_required")
        ),
        company_economical_code: Yup.string().required(
          t("validation:this_field_is_required")
        ),
        company_address: Yup.string().required(
          t("validation:this_field_is_required")
        ),
        company_sheba_code: Yup.string()
          .matches(
            /^(?:IR)(?=.{24}$)[0-9]*$/,
            t("validation:please_insert_valid_sheba")
          )
          .required(t("validation:this_field_is_required")),
      }),
      onSubmit: (values, actions) => {
        dispatcher(actionUpdateUserInfo(values));
        dispatcher(actionSetCurrentStep(CompleteInfoType.UploadDocuments));
      },
    });

  return (
    <Stack component="form" spacing={5} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack>
            <TitleWithValue
              title={t("common:company_name")}
              hasDoubleDot={false}
              colorTitle={"text.secondary"}
              value={
                <TextField
                  fullWidth
                  {...getFieldProps("company_name")}
                  error={Boolean(touched.company_name && errors.company_name)}
                  helperText={touched.company_name && errors.company_name}
                />
              }
              direction={"column"}
              alignItems="flex-start"
            />
          </Stack>
        </Grid>
        {isInvestor ? (
          <Grid item xs={12} md={6}>
            <Stack>
              <TitleWithValue
                title={t("common:sajam_code")}
                hasDoubleDot={false}
                colorTitle={"text.secondary"}
                value={
                  <TextField
                    fullWidth
                    {...getFieldProps("sajam_code")}
                    error={Boolean(touched.sajam_code && errors.sajam_code)}
                    helperText={touched.sajam_code && errors.sajam_code}
                  />
                }
                direction={"column"}
                alignItems="flex-start"
              />
            </Stack>
          </Grid>
        ) : null}
        <Grid item xs={12} md={6}>
          <Stack>
            <TitleWithValue
              title={t("common:international_code")}
              hasDoubleDot={false}
              colorTitle={"text.secondary"}
              value={
                <TextField
                  fullWidth
                  {...getFieldProps("international_code")}
                  type={"number"}
                />
              }
              direction={"column"}
              alignItems="flex-start"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <TitleWithValue
              title={t("common:company_bank_number")}
              hasDoubleDot={false}
              colorTitle={"text.secondary"}
              value={
                <TextField
                  fullWidth
                  type={"number"}
                  {...getFieldProps("company_bank_number")}
                  error={Boolean(
                    touched.company_bank_number && errors.company_bank_number
                  )}
                  helperText={
                    touched.company_bank_number && errors.company_bank_number
                  }
                />
              }
              direction={"column"}
              alignItems="flex-start"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <TitleWithValue
              title={t("common:registration_number")}
              hasDoubleDot={false}
              colorTitle={"text.secondary"}
              value={
                <TextField
                  fullWidth
                  type="number"
                  {...getFieldProps("company_economical_code")}
                  error={Boolean(
                    touched.company_economical_code &&
                      errors.company_economical_code
                  )}
                  helperText={
                    touched.company_economical_code &&
                    errors.company_economical_code
                  }
                />
              }
              direction={"column"}
              alignItems="flex-start"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <TitleWithValue
              title={t("common:account_sheba_number")}
              hasDoubleDot={false}
              colorTitle={"text.secondary"}
              value={
                <ShebaTextField
                  fullWidth
                  size="medium"
                  error={Boolean(
                    touched.company_sheba_code && errors.company_sheba_code
                  )}
                  helperText={
                    touched.company_sheba_code && errors.company_sheba_code
                  }
                  {...getFieldProps("company_sheba_code")}
                  onChange={(val) => {
                    setFieldValue(
                      "company_sheba_code",
                      val.replaceAll(" ", "")
                    );
                  }}
                />
              }
              direction={"column"}
              alignItems="flex-start"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <TitleWithValue
              title={t("common:economic_code")}
              hasDoubleDot={false}
              colorTitle={"text.secondary"}
              value={
                <TextField
                  fullWidth
                  type="number"
                  {...getFieldProps("economical_code")}
                  error={Boolean(
                    touched.economical_code && errors.economical_code
                  )}
                  helperText={touched.economical_code && errors.economical_code}
                />
              }
              direction={"column"}
              alignItems="flex-start"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <TitleWithValue
              title={t("common:company_address")}
              hasDoubleDot={false}
              colorTitle={"text.secondary"}
              value={
                <TextField
                  fullWidth
                  rows={3}
                  multiline
                  {...getFieldProps("company_address")}
                  error={Boolean(
                    touched.company_address && errors.company_address
                  )}
                  helperText={touched.company_address && errors.company_address}
                />
              }
              direction={"column"}
              alignItems="flex-start"
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack alignItems={{ xs: "initial", md: "start" }}>
        <AuthSubmitButton
          title={t("next_step")}
          btnType={"submit"}
          sxProps={{
            width: ["100%", , "200px"],
          }}
        />
      </Stack>
    </Stack>
  );
};

export default CompleteInformationForm;
