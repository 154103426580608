import * as React from "react"

import { Box, Button, IconButton, useMediaQuery, useTheme } from "@mui/material"

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import CloseIcon from "@mui/icons-material/Close"
import { useAppSelector } from "@/store/store"
import { useGlobalContext } from "@/context/global-context"
import { useTranslation } from "react-i18next"
import { userTypeSelector } from "@/store/slices/auth/selectors"

interface IUpdateInfoModalWrapperProps {
  handleBack?: Function
  children: React.ReactNode
}

const UpdateInfoModalWrapper: React.FunctionComponent<
  IUpdateInfoModalWrapperProps
> = ({ handleBack, children }) => {
  //getting translation object
  const { t } = useTranslation()
  const selectedType = useAppSelector(userTypeSelector)
  //getting mui theme
  const theme = useTheme()
  //checking mobile viewport
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  //access to close modal
  const { closeModal } = useGlobalContext()
  return (
    <Box sx={{ minWidth: 422, p: 1 }}>
      {(Boolean(isMobile && !selectedType) && (
        <IconButton onClick={() => closeModal()}>
          <CloseIcon />
        </IconButton>
      )) ||
        null}
      {(Boolean(handleBack && selectedType) && (
        <Button
          variant="text"
          color="inherit"
          size="small"
          {...(handleBack && { onClick: () => handleBack() })}
          endIcon={<ArrowBackIosNewIcon />}
          sx={{
            minWidth: "fit-content",
            position: "absolute",
            top: 10,
            right: 10
          }}
        >
          {t("common:coming_back")}
        </Button>
      )) ||
        null}
      {children}
    </Box>
  )
}

export default UpdateInfoModalWrapper
