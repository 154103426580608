import * as React from "react";

import { Chip, Theme, alpha, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";

interface IStatusBoxProps {
  status?: string;
  label?: string
}

const StatusBox: React.FunctionComponent<IStatusBoxProps> = ({ status , label}) => {
  // Getting Translation object
  const { t } = useTranslation(["common"]);
  // Getting mui theme
  const theme = useTheme<Theme>();
  // color box status
  let colorStatus: string;
  // color text status
  let colorText: string;
  // text status
  let textStatus: string = "";

  switch (status) {
    case "success": {
      colorText = theme.palette.success.light;
      colorStatus = alpha(theme.palette.success.light, 0.12);
      textStatus = t("common:paid");
      break;
    }
    case "Enabled": {
      colorText = theme.palette.success.light;
      colorStatus = alpha(theme.palette.success.light, 0.12);
      textStatus = t("common:enabled");
      break;
    }
    case "paid": {
      colorText = theme.palette.success.light;
      colorStatus = alpha(theme.palette.success.light, 0.12);
      textStatus = t("common:paid");
      break;
    }
    case "sent": {
      colorText = theme.palette.success.light;
      colorStatus = alpha(theme.palette.success.light, 0.12);
      textStatus = t("common:sent");
      break;
    }
    case "error": {
      colorText = theme.palette.error.main;
      colorStatus = alpha(theme.palette.error.main, 0.12);
      textStatus = t("common:error");
      break;
    }
    case "unpaid": {
      colorText = theme.palette.error.main;
      colorStatus = alpha(theme.palette.error.main, 0.12);
      textStatus = t("common:unpaid");
      break;
    }
    case "failed": {
      colorText = theme.palette.error.main;
      colorStatus = alpha(theme.palette.error.main, 0.12);
      textStatus = t("common:didnt_send");
      break;
    }
    case "Disabled": {
      colorText = theme.palette.error.main;
      colorStatus = alpha(theme.palette.error.main, 0.12);
      textStatus = t("common:disabled");
      break;
    }
    case "sending": {
      colorText = theme.palette.primary.main;
      colorStatus = alpha(theme.palette.primary.main, 0.12);
      textStatus = t("common:sending");
      break;
    }
    case "planned": {
      colorText = theme.palette.info.light;
      colorStatus = alpha(theme.palette.info.main, 0.12);
      textStatus = t("common:planned");
      break;
    }
    case "warning": {
      colorText = theme.palette.warning.main;
      colorStatus = alpha(theme.palette.warning.main, 0.12);
      textStatus = t("common:paying");
      break;
    }
    case "Pending": {
      colorText = theme.palette.warning.main;
      colorStatus = alpha(theme.palette.warning.main, 0.12);
      textStatus = t("common:pending");
      break;
    }
    case "Rejected": {
      colorText = theme.palette.error.main;
      colorStatus = alpha(theme.palette.error.main, 0.12);
      textStatus = t("common:rejected");
      break;
    }
    case "Accepted": {
      colorText = theme.palette.success.main;
      colorStatus = alpha(theme.palette.success.main, 0.12);
      textStatus = t("common:accepted");
      break;
    }
    case "awaiting-confirmation": {
      colorText = theme.palette.warning.main;
      colorStatus = alpha(theme.palette.warning.main, 0.12);
      textStatus = t("common:awaiting_confirmation");
      break;
    }
    default: {
      colorText = theme.palette.primary.light;
      colorStatus = alpha(theme.palette.primary.light, 0.12);
      textStatus = status;
      break;
    }
  }

  return (
    <Chip
      label={label || textStatus}
      sx={{ background: colorStatus, color: colorText }}
    />
  );
};

export default StatusBox;
