import * as React from "react"

import { Button } from "@mui/material"
import NoItemsWasFound from "."
import WarningIcon from "@mui/icons-material/Warning"
import routes from "@/navigation/routes"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface ICompleteProfileTypeAlertProps {}

const CompleteProfileTypeAlert: React.FunctionComponent<
  ICompleteProfileTypeAlertProps
> = (props) => {
  const { t } = useTranslation(["common"])

  const navigator = useNavigate()

  return (
    <NoItemsWasFound
      title={t("common:please_complete_profile")}
      icon={<WarningIcon />}
      afterTitle={
        <Button
          onClick={() => navigator(`/${routes.dashboard.index}`)}
          variant="outlined"
        >
          {t("common:go_to_profile")}
        </Button>
      }
    />
  )
}

export default CompleteProfileTypeAlert
