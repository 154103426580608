import * as React from "react";
import { Button } from "@mui/material";
import CompleteUserInformation from "@/components/pages/complete-information";
import { useGlobalContext } from "@/context/global-context";
import {
  actionSetCurrentStep,
  actionSetSelectedType,
  actionUpdateUserInfo,
} from "@/store/slices/auth";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

interface IAuthenticationButtonProps {}

const AuthenticationButton: React.FunctionComponent<
  IAuthenticationButtonProps
> = (props) => {
  //getting translation object
  const { t } = useTranslation();
  //getting show modal from context
  const { showModal } = useGlobalContext();
  const dispatcher = useDispatch();
  const handleCompleteInformation = () => {
    dispatcher(actionSetCurrentStep(null));
    dispatcher(actionSetSelectedType(null));
    dispatcher(actionUpdateUserInfo(null));
    showModal(<CompleteUserInformation />, {
      display: "flex",
      width: "auto",
      // pb: 0,
      m: "auto",
      // alignItems: "center",
      // "& .modal-content": {
      //   display: "flex",
      //   position: "relative",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   "& .modal-body": {
      //     display: "flex",
      //     alignItems: "center",
      //   },
      // },
    });
  };

  return (
    <Button onClick={handleCompleteInformation} variant="contained">
      {t("authentication")}
    </Button>
  );
};

export default AuthenticationButton;
