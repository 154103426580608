import * as Yup from "yup"
import { FunctionComponent, useState } from "react"
import { Stack, TextField } from "@mui/material"

import { Auth } from "@/services"
import { LoadingButton } from "@mui/lab"
import { toaster } from "@/components/shared/toaster"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { GoogleRecaptcha } from "@/components/shared"

interface IEnterNationalCodeProps {
  onSubmit?: (national_code: string) => void
}

const EnterNationalCode: FunctionComponent<IEnterNationalCodeProps> = ({
  onSubmit,
}) => {
  //Getting translation object
  const { t } = useTranslation(["common", "validation"])

  const [recaptcha, setRecapcha] = useState<string | null>(null)

  const { getFieldProps, errors, touched, handleSubmit, isSubmitting } =
    useFormik({
      initialValues: {
        national_code: "",
      },
      validationSchema: Yup.object().shape({
        national_code: Yup.string()
          .required(t("validation:this_field_is_required"))
          .min(10, t("common:please_insert_10_char_national_code"))
          .max(10, t("common:please_insert_10_char_national_code")),
      }),
      onSubmit: async (values, actions) => {
        if (!recaptcha)
          return toaster.warning(t("common:please_verify_recaptcha"))
        actions.setSubmitting(true)
        try {
          const res = await Auth.sejamStepOne(values.national_code, recaptcha)
          toaster.success(res.data.message)
          if (onSubmit) onSubmit(values.national_code)
        } catch (error) {
          toaster.error(error.response.data)
        } finally {
          actions.setSubmitting(false)
        }
      },
    })

  return (
    <Stack spacing={2} component={"form"} onSubmit={handleSubmit}>
      <TextField
        label={t("common:national_code")}
        error={Boolean(touched.national_code && errors.national_code)}
        helperText={touched.national_code && errors.national_code}
        {...getFieldProps("national_code")}
      />
      <GoogleRecaptcha handleChange={(recaptcha) => setRecapcha(recaptcha)} />
      <LoadingButton
        fullWidth
        disabled={!!!recaptcha}
        variant="contained"
        type="submit"
        loading={isSubmitting}
      >
        {t("common:get_one_time_password")}
      </LoadingButton>
    </Stack>
  )
}

export default EnterNationalCode
