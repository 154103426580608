// Built-in import
import * as React from "react";
// External Import
import { Stack, alpha, useMediaQuery, Divider, useTheme } from "@mui/material";
import { TitleWithValue } from "@/components/shared";
import { useTranslation } from "react-i18next";
import CopyClipBoardContent from "./copy-clipboard-content";
// Internal Import

interface IReceiverDetailsProps {
  item?: any;
}

const ReceiverDetails: React.FunctionComponent<IReceiverDetailsProps> = ({
  item,
}) => {
  // Getting Translation object
  const { t } = useTranslation(["common"]);
  //getting mui theme
  const theme = useTheme();
  //check viewport environment
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      sx={{
        width: "100%",
        border: "1px solid",
        borderColor: (t) => alpha(t.palette.common.black, 0.12),
        borderRadius: "12px",
      }}
      direction={["column", , "row"]}
      alignItems="center"
    >
      <Stack
        spacing={1}
        sx={{
          p: 2,
          width: "100%",
        }}
      >
        <TitleWithValue
          title={t("common:name")}
          value={item?.name}
          justifyContent="space-between"
          colorTitle="text.primary"
          variantValue="smallMedium"
        />
        <TitleWithValue
          title={t("common:last_name")}
          value={item?.lastName}
          justifyContent="space-between"
          colorTitle="text.primary"
          variantValue="smallMedium"
        />
        <TitleWithValue
          title={t("common:national_code")}
          value={item?.nationalCode}
          justifyContent="space-between"
          colorTitle="text.primary"
          variantValue="smallMedium"
        />
      </Stack>
      <Divider
        orientation={isMobile ? "horizontal" : "vertical"}
        sx={{
          width: ["100%", , "1px"],
          height: ["auto", , "100px"],
        }}
      />
      <Stack
        spacing={1}
        sx={{
          p: 2,
          width: "100%",
        }}
      >
        <TitleWithValue
          title={t("common:sheba_number")}
          value={<CopyClipBoardContent content={item?.shebaNumber} />}
          justifyContent="space-between"
          colorTitle="text.primary"
        />
        <TitleWithValue
          title={t("common:account_number")}
          value={<CopyClipBoardContent content={item?.accountNumber} />}
          justifyContent="space-between"
          colorTitle="text.primary"
        />
        <TitleWithValue
          title={t("common:bank_name")}
          value={item?.bankName}
          justifyContent="space-between"
          colorTitle="text.primary"
          variantValue="smallMedium"
        />
      </Stack>
    </Stack>
  );
};

export default ReceiverDetails;
